<template>
  <div>
    <v-row>
      <v-col
        md="3"
        sm="6"
        cols="12"
      >
        <v-card
          outlined
        >
          <v-card-text class="position-relative">
            <v-avatar
              size="60"
              color="primary"
              style="z-index: 10;"
              class="avatar-center"
            >
              <v-img :src="user.image ? user.image.original_url : require('@/assets/images/avatars/3.png')"></v-img>
            </v-avatar>
            <div class="d-flex justify-space-between flex-wrap pt-2">
              <div class="me-2 mx-5">
                <v-card-title class="pt-0 px-0">
                  {{ user.name }}
                </v-card-title>
                <v-card-subtitle class="text-xs pa-0">
                  <v-chip
                    :color="user.role.color"
                    small
                  >
                    {{ user.role.description }}
                  </v-chip>
                </v-card-subtitle>
              </div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="logout"
                  >
                    <v-icon>
                      mdi-exit-to-app
                    </v-icon>
                  </v-btn>
                </template>
                <span>اضغط لتسجيل الخروج</span>
              </v-tooltip>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-for="(statistic, index) in statistics"
        :key="index"
        cols="12"
        md="3"
        sm="6"
      >
        <statistics-card
          :title="statistic.title"
          :color="statistic.color"
          :icon="statistic.icon"
          :statistics="statistic.statistics"
          :show-overlay.sync="statistic.loading"
        ></statistics-card>
      </v-col>

      <!--      <statistics-cards-->
      <!--        :cols="12"-->
      <!--        :title="'stats'"-->
      <!--        :statistics="Object.values(statistics)"-->
      <!--        :refresh="fetch"-->
      <!--        :show-overlay.sync="loading"-->
      <!--      ></statistics-cards>-->
    </v-row>
    <v-divider></v-divider>
    <v-card-title class="pt-0 px-0">
      <br>
      الاحصائيات اليومية
    </v-card-title>
    <v-row>
      <v-col
        v-for="(statistic, index) in DailyStatistics"
        :key="index"
        cols="12"
        md="3"
        sm="6"
      >
        <statistics-card
          :title="statistic.title"
          :color="statistic.color"
          :icon="statistic.icon"
          :statistics="statistic.statistics"
          :show-overlay.sync="statistic.loading"
        ></statistics-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card-text style="color: red" class="text-center pt-15">
          العجلات التي ستنتهي مدتها قريبا
        </v-card-text>
        <v-data-table
          :headers="headersCars"
          :items="itemsCars"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions:[1,2,3,4,5,6,7,8,9,10],
            itemsPerPageText: $t('dataTable.itemsPerPageText'),
            pageText: $t('dataTable.pageText')
          }"
        >
          <template v-slot:item.expire_date="{ item }">
            <v-chip
              v-if="item.expire_date"
              label
              small
            >
              {{ item.expire_date | format_date }}
            </v-chip>
          </template>
          <template v-slot:item.created_at="{ item }">
            <v-chip
              v-if="item.created_at"
              label
              small
            >
              {{ item.created_at | format_date }}
            </v-chip>
          </template>

          <template v-slot:item.active="{ item }">
            <v-btn
              v-can="'update_peoples'"
              depressed
              :color="item.active ? 'success' : 'error'"
              rounded
              x-small
              outlined
            >
              {{ item.active ? 'مفعل' : 'معطل' }}
              <v-icon
                dark
                right
              >
                {{ item.active ? 'mdi-check' : 'mdi-block-helper' }}
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col
        class="text-center"
        cols="12"
        md="6"
      >
        <v-card-text style="color: red" class="text-center pt-15">
          الاشخاص الذين ستنتهي مدتهم قريبا
        </v-card-text>
        <v-data-table
          :headers="headersPeoples"
          :items="itemsPeoples"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions:[1,2,3,4,5,6,7,8,9,10],
            itemsPerPageText: $t('dataTable.itemsPerPageText'),
            pageText: $t('dataTable.pageText')
          }"
        >
          <template v-slot:item.expire_date="{ item }">
            <v-chip
              v-if="item.expire_date"
              label
              small
            >
              {{ item.expire_date | format_date }}
            </v-chip>
          </template>
          <template v-slot:item.created_at="{ item }">
            <v-chip
              v-if="item.created_at"
              label
              small
            >
              {{ item.created_at | format_date }}
            </v-chip>
          </template>

          <template v-slot:item.active="{ item }">
            <v-btn
              v-can="'update_peoples'"
              depressed
              :color="item.active ? 'success' : 'error'"
              rounded
              x-small
              outlined
            >
              {{ item.active ? 'مفعل' : 'معطل' }}
              <v-icon
                dark
                right
              >
                {{ item.active ? 'mdi-check' : 'mdi-block-helper' }}
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.identifier.name="{ item }">
            <v-chip
              v-if="item.identifier"
              label
              color="black"
              small
              dark
            >
              {{ item.identifier.name }}
            </v-chip>
          </template>
          <template v-slot:item.name="{ item }">
            <v-chip
              v-if="item.name"
              label
              color="black"
              small
              dark
            >
              {{ item.name.substring(0,15)+'...' }}
            </v-chip>
          </template>
          <template v-slot:item.enter_all_cars="{ item }">
            <v-chip
              :color="item.enter_all_cars ? 'success' : 'error'"
              label
              outlined
              small
            >
              {{ item.enter_all_cars ? 'مسموح' : 'غير مسموح' }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import {
  mdiAccountOutline, mdiCar, mdiAccountTie, mdiAlarmLightOutline,
  mdiAccountMultiple,
} from '@mdi/js'
import axios from '@axios'
import useVuetify from '@core/utils/vuetify'
import auth from '@/store/auth';
import StatisticsCard from '@/components/cards/StatisticsCard.vue';

// import StatisticsCards from '@/components/cards/StatisticsCards.vue'

export default {
  name: 'HomePage',
  components: {
    StatisticsCard,

    // StatisticsCards,
  },
  data: () => ({
    headersCars: [
      { text: 'الرقم', value: 'number' },
      { text: 'النوع', value: 'type' },
      { text: 'اللون', value: 'color' },
      { text: 'الحالة', value: 'active' },
      { text: 'تأريخ الانتهاء', value: 'expire_date' },
    ],
    headersPeoples: [
      { text: 'الاسم', value: 'name' },
      { text: 'المعرف', value: 'identifier.name' },
      { text: 'الدخول مع العجلات', value: 'enter_all_cars' },
      { text: 'الحالة', value: 'active' },
      { text: 'تأريخ الانتهاء', value: 'expire_date' },
    ],
    itemsCars: [],
    itemsPeoples: [],
    loading: true,

    // loading: true,
    statistics: {
      checkpoints: {
        title: 'statistics.checkpoints',
        statistics: 0,
        icon: mdiAlarmLightOutline,
        color: 'error',
        loading: true,
      },
      identifiers: {
        title: 'statistics.identifiers',
        statistics: 0,
        icon: mdiAccountTie,
        color: 'warning',
        loading: true,
      },
      people: {
        title: 'statistics.people',
        statistics: 0,
        icon: mdiAccountOutline,
        color: 'primary',
        loading: true,
      },
      citizen: {
        title: 'statistics.citizen',
        statistics: 0,
        icon: mdiAccountOutline,
        color: 'accent',
        loading: true,
      },
      car: {
        title: 'statistics.car',
        statistics: 0,
        icon: mdiCar,
        color: 'info',
        loading: true,
      },

    },
    DailyStatistics: {
      CountEntryDailyPeople: {
        title: 'statistics.CountEntryDailyPeople',
        statistics: 0,
        icon: mdiAccountMultiple,
        color: '#328da8',
        loading: true,
      },
      CountEntryDailyCitizen: {
        title: 'statistics.CountEntryDailyCitizen',
        statistics: 0,
        icon: mdiAccountMultiple,
        color: '#328da8',
        loading: true,
      },
      CountEntryDailyFuneral: {
        title: 'statistics.CountEntryDailyFuneral',
        statistics: 0,
        icon: mdiAccountMultiple,
        color: '#328da8',
        loading: true,
      },
      CountEntryDailyDignitary: {
        title: 'statistics.CountEntryDailyDignitary',
        statistics: 0,
        icon: mdiAccountMultiple,
        color: '#328da8',
        loading: true,
      },
      CountEntryDailyCall: {
        title: 'statistics.CountEntryDailyCall',
        statistics: 0,
        icon: mdiAccountMultiple,
        color: '#328da8',
        loading: true,
      },

    },

  }),
  setup() {
    const { rootThemeClasses } = useVuetify()

    return {
      rootThemeClasses,
    }
  },

  computed: {
    user() {
      return auth.state.user;
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      try {
        // this.loading = true;
        const response = await axios.get('/statistics')
        const responseExpiredCars = await axios.get('/exp_soon_cars');
        const responseExpiredPeoples = await axios.get('/exp_soon_peoples');
        this.itemsCars = responseExpiredCars.data.data
        this.itemsPeoples = responseExpiredPeoples.data.data
        this.loading = false
        this.citizens = response.data.data.data
        this.citizensTotal = response.data.data.total
        Object.keys(response.data.data)
          .forEach(key => {
            if (this.statistics[key]) {
              this.statistics[key].statistics = response.data.data[key];
              this.statistics[key].loading = false;
            }
          });
        Object.keys(response.data.data.DailyStatistics)
          .forEach(key => {
            if (this.DailyStatistics[key]) {
              this.DailyStatistics[key].statistics = response.data.data.DailyStatistics[key];
              this.DailyStatistics[key].loading = false;
            }
          });
      } finally {
        // this.loading = false
      }
    },
    logout() {
      auth.dispatch('logout')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.avatar-center {
  top: 1rem;
  right: -2rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}
</style>
